// @flow

import * as R from 'ramda';
import { command } from '@accordo-feed/micro-frontends';
import { core } from '@accordo-feed/micro-frontends-utils';

export const DEFAULT_LANGUAGE = 'en-us';
export const DEFAULT_COUNTRY_CODE = 'us';
export const LANGUAGE_CHOICE = 'language_choice';
export const SHELL_NAME = 'cloud-optimizer';
export const SUBMODULE_NAME = 'team-invites';
export const REDUCER_NAMESPACES = {
  SHELL: 'shell',
  SUBMODULE: `sub_${core.snakeToCamel(SUBMODULE_NAME)}`
};

const getSubmodulePath = path => `/team-management${path}`;
const getSubmodulePaths = (paths: Object): Object => R.map(getSubmodulePath, paths);

const RELATIVE_PATHS = {
  HOME: '/'
};

export const PATHS = getSubmodulePaths(RELATIVE_PATHS);

// NOTE: Make sure the event keys are exactly the same as they are in the shell:
// https://bitbucket.org/accordogroup/csa.frontend/src/master/src/common/constants.js
export const MF_COMMAND_EVENTS = {
  ...command.EVENTS
};

export const DUPLICATE_EMAIL_ERROR_CODE = 101037;

export const LOADING_STATUS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED'
};
