// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API, getApiAddress, requestHandler } from 'src/utils/api';

/***************
 *   HELPERS   *
 ***************/

const fetchMembersApi = getApiAddress(API.FETCH_MEMBERS);
const createInviteApi = getApiAddress(API.CREATE_INVITE);
const claimInviteApi = getApiAddress(API.CLAIM_INVITE);
const removeMemberApi = getApiAddress(API.REMOVE_MEMBER);

/*****************
 *   API CALLS   *
 *****************/

export const fetchMembers = (orgId: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(fetchMembersApi, { orgId })
  });

export const createInvite = ({ orgId, data }: { orgId: string, data: any }): Promise<any> =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(createInviteApi, { orgId }),
    data
  });

export const claimInvite = (data: any): Promise<any> =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(claimInviteApi),
    data
  });

export const removeMember = ({ orgId, memberId }: { orgId: string, memberId: string }): Promise<any> =>
  requestHandler({
    method: 'DELETE',
    url: encodeURI(core.replaceAll(removeMemberApi, { orgId, memberId }))
  });
