// @flow

import { apiCommon } from '@accordo-feed/micro-frontends-utils';

import { SUBMODULE_NAME } from 'src/constants';

/*************
 *   TYPES   *
 *************/

declare var __DEPLOYMENT_ENV__: string;

/***************
 *   HELPERS   *
 ***************/

const getSearchProxyPath = indexName => `/search-service/index/${indexName}/organizations/{{ orgId }}`;

/*******************
 *   API MAPPING   *
 *******************/

export const API = {
  SEARCH_BATCH_INDEXES: 'SEARCH_BATCH_INDEXES',
  ORG_STATE: 'ORG_STATE',
  FETCH_MEMBERS: 'FETCH_MEMBERS',
  CREATE_INVITE: 'CREATE_INVITE',
  CLAIM_INVITE: 'CLAIM_INVITE',
  REMOVE_MEMBER: 'REMOVE_MEMBER'
};

const apiMapping = {
  // IndexProxy:
  // https://bitbucket.org/accordogroup/search-service/src/master/docs/swagger.yml
  [API.SEARCH_BATCH_INDEXES]: `${getSearchProxyPath('batch')}`,

  // Organization API
  // https://bitbucket.org/accordogroup/organization-api/src/master/docs/swagger.yaml
  [API.FETCH_MEMBERS]: `/organizations/aco/msp/{{ orgId }}/members`,
  [API.CREATE_INVITE]: `/organizations/aco/msp/{{ orgId }}/invite`,
  [API.CLAIM_INVITE]: `/organizations/aco/msp/claim-invite`,
  [API.REMOVE_MEMBER]: `/organizations/aco/msp/{{ orgId }}/members/{{ memberId }}`
};

const apiConfig = new apiCommon.ApiConfig({
  apiMapping,
  env: __DEPLOYMENT_ENV__,
  storageName: SUBMODULE_NAME
});

export const getApiAddress = (key: string): string => apiConfig.get(key);

export const requestHandler = apiCommon.requestHandler(SUBMODULE_NAME);
