import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('teamManagement.notifications');
const genOpts = langHelper.genLangOptions(genId);

export default {
  sendingInviteMsg: genOpts('sendingInviteMsg', 'Processing your request'),
  inviteSuccessMsg: genOpts('inviteSuccessMsg', 'An invite link has been emailed to your Team Member'),
  errorMsg: genOpts('errorMsg', 'An error has occured'),
  memberRemovalSuccessMsg: genOpts('memberRemovalSuccessMsg', '{email} removed'),
  memberRemovalSuccessDesc: genOpts('memberRemovalSuccessDesc', 'They will no longer be able to access your account')
};
