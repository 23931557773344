// @flow

import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type ApiType = {
  data: Array<Object>,
  isLoaded: boolean,
  isLoading: boolean
};

type StateType = {
  members: ApiType,
  emailList: Array<string>,
  isSendingInvites: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  members: {
    data: [],
    isLoaded: false,
    isLoading: false
  },
  emailList: [],
  isSendingInvites: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/invites');

const FETCH_MEMBERS = wrapWithNamespace('FETCH_MEMBERS');
const FETCH_MEMBERS_SUCCESS = wrapWithNamespace('FETCH_MEMBERS_SUCCESS');
const SET_MEMBERS_LOADING_STATE = wrapWithNamespace('SET_MEMBERS_LOADING_STATE');
const CREATE_INVITES = wrapWithNamespace('CREATE_INVITES');
const CREATE_INVITES_SUCCESS = wrapWithNamespace('CREATE_INVITES_SUCCESS');
const SET_IS_SENDING_INVITES = wrapWithNamespace('SET_IS_SENDING_INVITES');
const CLAIM_INVITE = wrapWithNamespace('CLAIM_INVITE');
const CLAIM_INVITE_SUCCESS = wrapWithNamespace('CREATE_INVITE_SUCCESS');
const REMOVE_MEMBER = wrapWithNamespace('REMOVE_MEMBER');
const REMOVE_MEMBER_SUCCESS = wrapWithNamespace('REMOVE_MEMBER_SUCCESS');
const ADD_INVITE_EMAIL = wrapWithNamespace('ADD_INVITE_EMAIL');
const REMOVE_INVITE_EMAIL = wrapWithNamespace('REMOVE_INVITE_EMAIL');

export const fetchMembers = createAction(FETCH_MEMBERS);
export const fetchMembersSuccess = createAction(FETCH_MEMBERS_SUCCESS);
export const setMembersLoadingState = createAction(SET_MEMBERS_LOADING_STATE);
export const createInvites = createAction(CREATE_INVITES);
export const createInvitesSuccess = createAction(CREATE_INVITES_SUCCESS);
export const setIsSendingInvites = createAction(SET_IS_SENDING_INVITES);
export const claimInvite = createAction(CLAIM_INVITE);
export const claimInviteSuccess = createAction(CLAIM_INVITE_SUCCESS);
export const removeMember = createAction(REMOVE_MEMBER);
export const removeMemberSuccess = createAction(REMOVE_MEMBER_SUCCESS);
export const addInviteEmail = createAction(ADD_INVITE_EMAIL);
export const removeInviteEmail = createAction(REMOVE_INVITE_EMAIL);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [FETCH_MEMBERS_SUCCESS]: (state, data) =>
      R.mergeDeepRight(state, {
        members: {
          data,
          isLoaded: true,
          isLoading: false
        }
      }),

    [SET_MEMBERS_LOADING_STATE]: (state, payload) => R.assocPath(['members', 'isLoading'], payload, state),

    [REMOVE_MEMBER_SUCCESS]: (state, memberId) => {
      const { members } = state;

      return {
        ...state,
        members: {
          data: R.filter(member => member.id !== memberId, members.data)
        }
      };
    },

    [ADD_INVITE_EMAIL]: (state, payload) => {
      const emailList = R.uniq([...state.emailList, payload]);
      return { ...state, emailList };
    },

    [REMOVE_INVITE_EMAIL]: (state, payload) => {
      const emailList = R.filter(email => email !== payload, state.emailList);
      return { ...state, emailList };
    },

    [CREATE_INVITES_SUCCESS]: (state, payload) =>
      R.mergeDeepRight(state, {
        members: {
          data: payload,
          isLoaded: true,
          isLoading: false
        },
        emailList: []
      }),

    [SET_IS_SENDING_INVITES]: setState('isSendingInvites')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
