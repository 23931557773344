import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('invites');

export const membersSelector = createSelector(moduleSelector, R.path(['members', 'data']));

export const emailListSelector = createSelector(moduleSelector, R.prop('emailList'));

export const isSendingSelector = createSelector(moduleSelector, R.prop('isSendingInvites'));
