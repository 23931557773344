// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { redux } from '@accordo-feed/micro-frontends';

import { SUBMODULE_NAME, REDUCER_NAMESPACES } from 'src/constants';

export const wrapWithModule = R.curry(redux.getActionWrapper)(SUBMODULE_NAME);

export const getModuleSelector = (key: string, namespace: string = REDUCER_NAMESPACES.SUBMODULE) =>
  createSelector(
    state => R.path([namespace, key], state),
    module => module
  );
