import * as R from 'ramda';
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';
import { notification } from 'antd';

import * as actions from './invites.duck';
import * as mspDataSelectors from 'src/redux/modules/mspData/mspData.selector';
import * as invitesSelectors from 'src/redux/modules/invites/invites.selector';
import * as apiCalls from './invites.api';
import lang from './invites.lang';

import { DUPLICATE_EMAIL_ERROR_CODE } from 'src/constants';

const showSendingInviteNotification = translate =>
  notification.info({
    message: translate(lang.sendingInviteMsg),
    key: 'inviteToast'
  });

const showInviteSuccessNotification = translate =>
  notification.success({
    message: translate(lang.inviteSuccessMsg),
    key: 'inviteToast',
    duration: 5
  });

const showInviteErrorNotification = message =>
  notification.error({
    message: message,
    key: 'inviteToast',
    duration: 5
  });

const showMemberRemovalSuccessNotification = translate => email =>
  notification.info({
    message: translate(lang.memberRemovalSuccessMsg, { email }),
    description: translate(lang.memberRemovalSuccessDesc),
    key: 'memberRemovalToast',
    duration: 5
  });

function* fetchMembersSaga() {
  const orgId = yield select(mspDataSelectors.orgIdSelector);
  yield put(actions.setMembersLoadingState(true));

  try {
    const data = yield apiCalls.fetchMembers(orgId);
    yield put(actions.setMembersLoadingState(false));
    yield put(actions.fetchMembersSuccess(data));
  } catch (err) {
    yield put(actions.setMembersLoadingState(false));
  }
}

function* createInvitesSaga({ payload }) {
  const { translate } = payload;
  const orgId = yield select(mspDataSelectors.orgIdSelector);
  const emailList = yield select(invitesSelectors.emailListSelector);
  yield put(actions.setIsSendingInvites(true));

  showSendingInviteNotification(translate);

  try {
    const data = yield all(
      emailList.map(email =>
        apiCalls.createInvite({
          orgId,
          data: { email }
        })
      )
    );

    const members = yield select(invitesSelectors.membersSelector);
    const newMembers = R.reject(R.isNil, [R.head(members), ...data, ...R.tail(members)]);

    yield put(actions.setIsSendingInvites(false));
    yield put(actions.createInvitesSuccess(newMembers));

    showInviteSuccessNotification(translate);
  } catch (e) {
    const errorCode = e.response.data.code;
    const toasterMessage =
      errorCode === DUPLICATE_EMAIL_ERROR_CODE ? e.response.data.message : translate(lang.errorMsg);

    showInviteErrorNotification(toasterMessage);

    yield put(actions.setIsSendingInvites(false));
  }
}

function* claimInviteSaga({ payload }) {
  const { data } = payload;

  try {
    const result = yield apiCalls.createInvite(data);
    yield put(actions.claimInviteSuccess(result));
  } catch (e) {}
}

function* removeMemberSaga({ payload }) {
  const { id, email, translate } = payload;
  const orgId = yield select(mspDataSelectors.orgIdSelector);

  try {
    yield apiCalls.removeMember({ orgId, memberId: id });
    yield put(actions.removeMemberSuccess(id));

    showMemberRemovalSuccessNotification(translate)(email);
  } catch (e) {}
}

export default function*() {
  yield all([fork(takeLatest, actions.fetchMembers, fetchMembersSaga)]);
  yield all([fork(takeLatest, actions.createInvites, createInvitesSaga)]);
  yield all([fork(takeLatest, actions.claimInvite, claimInviteSaga)]);
  yield all([fork(takeLatest, actions.removeMember, removeMemberSaga)]);
}
